.folder-and-file-select-drag-drop {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px;
  flex: 1;
  justify-content: center;
}

.folder-and-file-select-drag-drop-label {
  padding: 8px;
  cursor: pointer;
  font-weight: 600;
  color: #2484ff;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
